<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Jobs" to="/jobs/list/1" />
      <q-breadcrumbs-el label="Contract Types" />
    </q-breadcrumbs>

  <div class="text-h5 q-mt-md">Jobs by Contract Type - Totals</div>

  <div class="row">
    <div v-for="contractType in contractTypeTotals" :key="contractType._id" class="col-6 col-sm-4 q-pa-sm">
      <router-link :to="{ name: 'jobContractTypeList', params: { contractType: contractType._id, pageId: 1 }}" class="text-primary text-bold">{{contractType._id}}</router-link>: {{contractType.count}}
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import jobModule from "../../../store";

export default {
  data() {
    return {
    }
  },
  computed: {
      ...mapState('job', {
        loading: 'loading',
      }),
      // posts: this.$store.getters['post/getPageSet'](this.$route.params.pageId),
      contractTypeTotals() {
        return this.$store.getters['job/contractTypeTotalsAlpha'] || [];
      },
  },

  created () {
    this.reqData();
  },

  methods: {
    reqData (force) {
      if (! this.contractTypeTotals || force) {
        this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadTotalsRequest);
      }
    },
  },
}
</script>



